//$primary: #00d1b2;
//$background: #96e8dc;
//$background2: #b1f5e8;

$primary: #0e872c;
$background: #f9f9f9;
$background2: #f9f9f9;
//$background: #afe896;
$error: #dd0008;
$success: $primary;

//$button: #0e872c;
