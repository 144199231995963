@import "colors";
@import "reset";
@import "../../../../../node_modules/@fortawesome/fontawesome-free/css/all.css";



label.checkbox input {
  margin-right: 10px;
}

button {
  font-weight: bold;
}

input::placeholder, .placeholder, select:invalid {
  color: lightgray !important;
}

.loadable {
  position: relative;
}

.page {
  @extend .loadable;
  padding: 40px;
  min-height: 80vh;
}

.columns {
  margin: 0;
}


.errors {
  color: $error;
}

.form-field-error {
  color: $error;
}

.global-notification {
  position: fixed;
  right: 50px;
  bottom: 50px;
  max-width: 400px;
  z-index: 999;
}

.title, .subtitle {
  text-transform: uppercase;
  font-weight: 600;
}
.field:not(:last-child) {
  margin-bottom: 24px;
}

.link {
  font-weight: bold;
  text-decoration: underline;
}
