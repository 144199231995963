@import "../../../../../node_modules/bulma/bulma";

input {
  &:not([type]), &[type=text], &[type=password], &[type=number], &[type=date], &[type=datetime-local] {
    @extend .input;
  }
}


textarea {
  @extend .textarea;
}

label {
  @extend .label;
}

button {
  @extend .button;
}

.columns:last-child {
  margin: 0;
}

form {
  & .column:first-child {
    padding-left: 0;
  }

  .column:last-child {
    padding-right: 0;
  }
}

